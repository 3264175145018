import { memoize } from "lodash";
import React, { FC, useMemo } from "react";

import HiddenSubsection from "components/UI/_TODO/SectionsWithCheckedExpenditures/components/HiddenSubsection/HiddenSubsection";
import HideBlock from "components/UI/atoms/_TODO/HideBlock";

import { IEstimateTargetWithType } from "../SpecificationAddRelationsModal";
import { IStructWithRecursiveChildren } from "../useSpecificationRelationsData";

import ISection from "types/interfaces/Section";

import { stringifyArgs } from "utils/helpers/stringifyArgs";

import styles from "./SpecificationRelationsSections.module.scss";

export interface ISectionsWithCheckedExpendituresProps {
  sections: IStructWithRecursiveChildren[];
  opened: boolean;
  selectedEstimateTarget: IEstimateTargetWithType | null;
  onCheckExpenditure: any; //(target: IEstimateTarget, isChecked: boolean) => void;
  isDisabled?: boolean;
  expendituresBySections?: any; //IExpenditure[];
  onOpenHiddenSubsection?: (id: number) => void;
  externalCheckedExpenditures?: any; //Record<IExpenditure["id"], boolean>;
  isExternalMultiple?: boolean;
}

const SpecificationRelationsSections: FC<ISectionsWithCheckedExpendituresProps> = ({
  sections,
  opened,
  selectedEstimateTarget,
  onCheckExpenditure,
  expendituresBySections,
  onOpenHiddenSubsection,
  externalCheckedExpenditures,
  isExternalMultiple,
}) => {
  const checkedExpenditures = useMemo(() => {
    if (!!externalCheckedExpenditures) {
      return externalCheckedExpenditures;
    }
    return selectedEstimateTarget?.expenditureId ? { [selectedEstimateTarget.expenditureId]: true } : {};
  }, [selectedEstimateTarget, externalCheckedExpenditures, isExternalMultiple]);

  const partialCheckExpenditure =
    (sectionId: ISection["id"]) => (estimateTarget: Omit<IEstimateTargetWithType, "sectionId">, isChecked: boolean) => {
      onCheckExpenditure(
        {
          ...estimateTarget,
          sectionId,
        },
        isChecked
      );
    };

  const memoizedPartialCheckExpenditure = useMemo(
    () => memoize(partialCheckExpenditure, stringifyArgs),
    [onCheckExpenditure]
  );

  //TODO сделать рекурсивно для любой вложенности секций
  return (
    <div>
      {sections.map((type) => (
        <HideBlock
          titleClassName={styles.sectionTitleClassName}
          title={type.name}
          key={type.id}
          isHiddenDefault={
            !(
              selectedEstimateTarget?.sectionId === type?.id ||
              opened ||
              type.children?.some((s) => s.children?.some((el) => checkedExpenditures[el.id]))
            )
          }
        >
          {type.children?.map((section) => (
            <div key={section.id} className={styles.section}>
              <HideBlock
                titleClassName={styles.sectionTitleClassName}
                title={section.name}
                isHiddenDefault={
                  !(
                    selectedEstimateTarget?.sectionId === section?.id ||
                    opened ||
                    section.children?.some((s) => s.children?.some((el) => checkedExpenditures[el.id]))
                  )
                }
              >
                <div className={styles.subSection}>
                  {Array.isArray(section.children) &&
                    section.children.map((subsection) => (
                      <HiddenSubsection
                        subsection={subsection}
                        opened={opened || subsection.children?.some((el) => checkedExpenditures[el.id])}
                        expenditures={subsection.children ?? expendituresBySections?.[subsection.id]}
                        expendituresAreLoading={!subsection.children && !expendituresBySections?.[subsection.id]}
                        selectedEstimateTarget={selectedEstimateTarget}
                        checkedExpenditures={checkedExpenditures}
                        onCheckExpenditure={memoizedPartialCheckExpenditure(section.id)}
                        key={subsection.id}
                        onOpenHiddenSubsection={onOpenHiddenSubsection}
                      />
                    ))}
                </div>
              </HideBlock>
            </div>
          ))}
        </HideBlock>
      ))}
    </div>
  );
};

export default React.memo(SpecificationRelationsSections);
