import { message } from "antd";
import axios from "axios";
import cn from "classnames";
import moment from "moment";
import React, { MouseEventHandler, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setGroupDetailInfo } from "redux/modules/common/building/journal/actions";
import { AosrTypes } from "redux/modules/common/building/journal/aosr/types";
import {
  acceptGroupTicketToActing,
  acceptTicketToActing,
} from "redux/modules/common/building/journal/journalExecution/thunks";
import { IExpendituresInJournalFulfillment } from "redux/modules/common/building/journal/journalExecution/types";
import { journalGroupDetailInfoSelector } from "redux/modules/common/building/journal/selectors";
import { getGroupDetailInfo } from "redux/modules/common/building/journal/thunks";

import ProductInfo from "../../../../../JournalDelivery/components/ProductInfo";
import AcceptTicketModal, {
  AcceptTicket,
} from "../../../JournalExecTicket/components/AcceptTicketModal/AcceptTicketModal";
import JournalExecActions from "../../../JournalExecTicket/components/Actions/JournalExecActions";
import ActCreateModal from "../../../JournalExecTicket/components/Aosr/ActCreateModal";
import AosrExistingModal from "../../../JournalExecTicket/components/Aosr/AosrExistingModal/AosrExistingModal";
import InfoPopup from "../../../JournalExecTicket/components/InfoPopup/InfoPopup";
import JournalReplacementsModal from "../../../JournalExecTicket/components/WorkRow/JournalReplacementsModal/JournalReplacementsModal";
import getReplacementIcon from "../../../JournalExecTicket/components/WorkRow/getReplacementIcon";
import AookExistingModal from "./../../../../components/JournalExecTicket/components/Aook/AookExistingModal/AookExistingModal";
import MatchingIcon, { MatchingStatusesEnum } from "_LEGACY/UI/MatchingIcon/MatchingIcon";
import ExpenditureGroupModal from "components/UI/_TODO/ExpenditureGroupModal/ui/ExpenditureGroupModal";
import JornalDoneModal from "components/UI/_TODO/WorkOrMaterialsModals/JournalDoneModal/JournalDoneModal";
import CreateRemarkModal from "components/modals/Journal/Remarks/CreateRemarkModal/CreateRemarkModal";
import RemarkModal from "components/modals/Journal/Remarks/RemarkModal/RemarkModal";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import JournalExecFiles from "../JournalExecFiles/JournalExecFiles";
import JournalExecRowContent from "../JournalExecRowContent/JournalExecRowContent";
import JournalExecRowGroupContent from "../JournalExecRowContent/JournalExecRowGroupContent";
import JournalExecMatching from "./JournalExecMatching/JournalExecMatching";
import SpecificationModal from "entities/SpecificationModal/SpecificationModal";
import BlueLabel from "shared/ui/dataDisplay/BlueLabel/BlueLabel";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import {
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_CREATE_REMARK,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_EDIT_AOSR,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_FORMATION_AOSR,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_IMPORT_AOSR,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_TRANSFER_TO_ACTS,
} from "constants/permissions/manufacturingPermissions";
import { ExpenditureTypeEnum } from "types/enums/ExpenditureTypeEnum";
import { IExpenditure } from "types/interfaces/Expenditure";

import usePermission from "hooks/usePermission";

import { dropNonSignificantZeros } from "utils/formatters/dropNonSignificantZeros";
import { sliceTextByConstraint } from "utils/formatters/sliceTextByConstraint";
import { splitThousands } from "utils/formatters/splitThousands";

import styles from "./JournalExecRow.module.scss";

interface IProps {
  ticket: IExpendituresInJournalFulfillment["items"][0];
  sectionId: number;
  isHideRemarks?: boolean;
  onRowClick?: (item: IExpendituresInJournalFulfillment["items"][0]) => void;
  isExpenditureInGroup?: boolean;
  groupId?: number;
  year: number;
  month: number;
  stockId: number;
}

const JournalExecRow: React.FC<IProps> = ({
  ticket,
  isHideRemarks,
  onRowClick,
  sectionId,
  isExpenditureInGroup,
  groupId,
  year,
  month,
  stockId,
}) => {
  const dispatch = useDispatch();
  const objectId = useObjectId();
  const isExpenditureGroup = ticket.type === "group";
  const ticketItem = ticket.expenditure ?? ticket.group ?? ticket.linked;
  const itemId = (ticketItem as any)?.expenditure_id ?? (ticketItem as any)?.group_id;
  const justification = (ticketItem as { justification: string })?.justification;
  const confirmedCount = ticketItem?.count;
  const measure = ticketItem?.measure;
  const materialsAmount = ticketItem?.amount_materials;
  const confirmedAmountWithMaterials = ticketItem?.amount;
  const ear = ticketItem?.ear;
  const replacement = ticketItem?.replacement;
  const expendituresCount = (ticketItem as IExpendituresInJournalFulfillment["items"][0]["group"])?.count_expenditures;

  const haveFormationAosrPermission = usePermission(VIEW_MANUFACTURING_JOURNAL_PROGRESS_FORMATION_AOSR);
  //const haveEditAosrPermission = usePermission(VIEW_MANUFACTURING_JOURNAL_PROGRESS_EDIT_AOSR);
  //const haveImportAosrPermission = usePermission(VIEW_MANUFACTURING_JOURNAL_PROGRESS_IMPORT_AOSR);
  const haveCreateRemarkPermission = usePermission(VIEW_MANUFACTURING_JOURNAL_PROGRESS_CREATE_REMARK);
  const haveTransferToActsPermission = usePermission(VIEW_MANUFACTURING_JOURNAL_PROGRESS_TRANSFER_TO_ACTS);

  const [isOpened, setIsOpened] = React.useState(false);

  const [isOpenJustification, setIsOpenJustification] = React.useState(false);
  const handleJustificationClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsOpenJustification(true);
  };

  const [isShowGroupModal, setIsShowGroupModal] = React.useState(false);
  const openGroupModal = (event: React.MouseEvent) => {
    event.stopPropagation();

    dispatch(getGroupDetailInfo(objectId, (ticketItem as any).group_id)); //TODO replace
    setIsShowGroupModal(true);
  };

  React.useEffect(() => {
    if (isShowGroupModal) return;
    dispatch(setGroupDetailInfo(null)); //TODO replace
  }, [isShowGroupModal]);

  const [isOpenAddRemarkModal, setIsOpenAddRemarkModal] = React.useState(false); //TODO replace
  const [isOpenRemarkModal, setIsOpenRemarkModal] = React.useState(false); //TODO replace
  const [isShowOnlyAccepted, setIsShowOnlyAccepted] = React.useState(false); //TODO replace

  const handleOpenRemarkModal = (onlyAccepted: boolean, count: number) => {
    if (!count || count === 0) return; //TODO replace

    setIsOpenRemarkModal(true);

    if (onlyAccepted) {
      setIsShowOnlyAccepted(true);
    } else {
      setIsShowOnlyAccepted(false);
    }
  };

  const handleOpenAddRemarkModal = () => {
    setIsOpenAddRemarkModal(true);
  };

  const [isAcceptingModal, setIsAcceptingModal] = React.useState(false); // TODO replace
  const openAcceptModalHandler = () => {
    // TODO replace
    if (!!ticketItem?.count_remarks_in_work) {
      message.error("Сначала устраните замечания");
      return;
    }
    setIsAcceptingModal(true);
  };

  const closeAcceptModal = React.useCallback(() => {
    // TODO replace
    setIsAcceptingModal(false);
  }, []);

  /* const acceptTicket: AcceptTicket = useCallback(
    ({ services, materials, expenditure: afterAcceptingExpenditure }) => {
      const currentActingInfo = isExpenditureGroup ? groupTicketActingInfo : ticketActingInfo;
      const acceptedActingInfo = {
        ...currentActingInfo,
        expenditure: {
          ...expenditure,
          sum_accepted_count: afterAcceptingExpenditure.isSelected ? afterAcceptingExpenditure.count : 0,
        },
        services,
        materials,
      };

      if (!isExpenditureGroup) {
        dispatch(acceptTicketToActing(objectId, expenditure.id, year, month, sectionId, acceptedActingInfo));
      } else {
        dispatch(acceptGroupTicketToActing(objectId, expenditure.id, year, month, sectionId, acceptedActingInfo));
      }
    },
    [objectId, expenditure.id, year, month, sectionId, ticketActingInfo, groupTicketActingInfo, isExpenditureGroup]
  ); */
  const [isOpenProductInfo, setIsOpenProductInfo] = React.useState<any>(undefined);

  const [isAosrCreating, setIsAosrCreating] = React.useState(false);
  const [isAosrModal, setIsAosrModal] = React.useState(false);
  const [isReplacementsModal, setIsReplacementsModal] = React.useState(false);

  const [isJustificationLoading, setIsJustificationLoading] = React.useState(false);
  const [justificationData, setJustificationData] = React.useState<
    Pick<IExpenditure, "name" | "measure" | "count" | "id">[] | null
  >(null);
  React.useEffect(() => {
    if (!isOpenJustification) return;
    setIsJustificationLoading(true);
    axios
      .get(`/building/${objectId}/relates/expenditures/${itemId}/`)
      .then((resp) => setJustificationData(resp.data.expenditures))
      .finally(() => setIsJustificationLoading(false));
  }, [isOpenJustification, objectId, itemId]);

  const [isTicketModal, setIsTicketModal] = React.useState(false);
  const clickExpenditure = () => {
    if (isExpenditureGroup || isExpenditureInGroup) return;
    setIsTicketModal(true);
  };

  const handleAosrClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsAosrModal(true);
  };
  const haveEditAosrPermission = usePermission(VIEW_MANUFACTURING_JOURNAL_PROGRESS_EDIT_AOSR);
  const haveImportAosrPermission = usePermission(VIEW_MANUFACTURING_JOURNAL_PROGRESS_IMPORT_AOSR);

  const aook = ticketItem?.actual_aooks?.at(-1) ?? ticketItem?.old_aook;
  const aooks = React.useMemo(
    () => (ticketItem?.actual_aooks?.length ? ticketItem?.actual_aooks : [ticketItem?.old_aook].filter((el) => !!el)),
    [ticketItem]
  );
  const [activeAook, setActiveAook] = React.useState<typeof aook | null>(null);
  const handleAookClick = (e: React.MouseEvent, act: typeof aook) => {
    e.stopPropagation();
    setActiveAook(act);
  };

  const acceptTicket: AcceptTicket = useCallback(
    ({ services, materials, expenditure: afterAcceptingExpenditure }) => {
      const currentActingInfo = ticketItem;
      const acceptedActingInfo = {
        ...currentActingInfo,
        expenditure: {
          ...ticketItem,
          sum_accepted_count: afterAcceptingExpenditure.isSelected ? afterAcceptingExpenditure.count : 0,
        },
        services,
        materials,
      };

      if (!isExpenditureGroup) {
        dispatch(acceptTicketToActing(objectId, itemId, year, month, sectionId, acceptedActingInfo as any));
      } else {
        dispatch(acceptGroupTicketToActing(objectId, itemId, year, month, sectionId, acceptedActingInfo as any));
      }
    },
    [objectId, itemId, year, month, sectionId, ticketItem, isExpenditureGroup]
  );

  return (
    <TableReusableRow
      className={cn(styles.row, { [styles.inGroupRow]: isExpenditureInGroup })}
      containerClassName={cn(styles.rowContainer, { [styles.inGroup]: isExpenditureInGroup })}
      isHoverable={false}
      isExpandable={!!ear?.status && !isExpenditureInGroup}
      isEarBlue={ear?.status === "blue"}
      isEarGreen={ear?.status === "green"}
      earCount={ear?.count!}
      isEarCounter={!!ear?.count}
      onExpand={() => {
        setIsOpened(true);
      }}
      onCollapse={() => {
        setIsOpened(false);
      }}
      innerContent={
        !isExpenditureGroup ? (
          <JournalExecRowContent ticket={ticket} year={year} month={month} sectionId={sectionId} />
        ) : (
          <JournalExecRowGroupContent ticket={ticket} year={year} month={month} sectionId={sectionId} />
        )
      }
    >
      <TableReusableCell className={styles.info} onClick={clickExpenditure}>
        <div className={styles.nameContainer}>
          <div className={styles.name}>{ticketItem?.name}</div>
          <div className={styles.labels}>
            {justification && (
              <BlueLabel
                onClick={handleJustificationClick}
                className={cn(styles.blueLabel, {
                  [styles.blueLabelNonActive]: !isOpened,
                })}
                blueFont={undefined}
              >
                {sliceTextByConstraint(justification, 30)}
              </BlueLabel>
            )}
            {isExpenditureGroup && !!expendituresCount && (
              <BlueLabel
                onClick={openGroupModal}
                className={cn(styles.blueLabel, {
                  [styles.blueLabelNonActive]: !isOpened,
                })}
                blueFont
              >
                Расценок: {expendituresCount}
              </BlueLabel>
            )}
            {!!replacement && (
              <BlueLabel
                blueFont
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  setIsReplacementsModal(true);
                }}
                className={cn(styles.blueLabel, styles.replacement, {
                  [styles.blueLabelNonActive]: !isOpened,
                })}
              >
                Замена&nbsp;
                {getReplacementIcon(ticket)}
              </BlueLabel>
            )}
            {ticketItem?.aosr && (
              <BlueLabel
                onClick={handleAosrClick}
                className={cn(styles.blueLabel, {
                  [styles.blueLabelNonActive]: !isOpened,
                  [styles.greenLabel]: ticketItem?.aosr?.status === "close",
                })}
                blueFont
              >
                АОСР от {moment(ticketItem?.aosr?.act_date).format("DD.MM.YYYY")}
              </BlueLabel>
            )}
            {!!aooks &&
              aooks.map((el) => (
                <BlueLabel
                  onClick={(e: React.MouseEvent) => handleAookClick(e, el)}
                  className={cn(styles.blueLabel, {
                    [styles.blueLabelNonActive]: !isOpened,
                    [styles.greenLabel]: el?.status === "close",
                  })}
                  blueFont
                >
                  АООК от {moment(el?.act_date).format("DD.MM.YYYY")}
                </BlueLabel>
              ))}
            {!isExpenditureGroup && (
              <JournalExecFiles
                name={ticketItem?.name!}
                expenditureId={itemId}
                expenditureType={ExpenditureTypeEnum.work}
                count={ticketItem?.attachments?.count!}
                isExpired={!!ticketItem?.attachments?.has_expired}
                year={year}
                month={month}
              />
            )}
          </div>
        </div>

        <div>
          <JournalExecMatching
            styles={styles}
            ticket={ticket}
            isShowGroupModal={isShowGroupModal}
            setIsShowGroupModal={setIsShowGroupModal}
          />
        </div>
      </TableReusableCell>
      <TableReusableCell className={cn(styles.rowItem, styles.doneRow)}>
        <>
          {dropNonSignificantZeros(confirmedCount)}&nbsp;&nbsp;(
          {sliceTextByConstraint(measure, 8)})
          <InfoPopup data={ticket} objectId={objectId} isHideHistoryButton={isExpenditureGroup} />
        </>
        <div className={styles.divider} />
      </TableReusableCell>

      <TableReusableCell className={cn(styles.rowItem, styles.righted)}>
        <div className={styles.righted}>{splitThousands(materialsAmount, false, false)}</div>
        <div className={styles.divider} />
      </TableReusableCell>
      <TableReusableCell className={cn(styles.rowItem, styles.righted)}>
        <div className={styles.righted}>{splitThousands(confirmedAmountWithMaterials, false, false)}</div>
        <div className={styles.divider} />
      </TableReusableCell>
      {isExpenditureInGroup && <div />}
      <JournalExecActions
        ticket={ticket}
        isHideRemarks={isHideRemarks}
        isShowFormationAosr={haveFormationAosrPermission}
        isShowCreateRemark={haveCreateRemarkPermission}
        isShowTransferToAct={haveTransferToActsPermission && !isExpenditureInGroup}
        handleOpenAddRemarkModal={handleOpenAddRemarkModal}
        handleOpenRemarkModal={handleOpenRemarkModal}
        onAcceptTicket={() => setIsAcceptingModal(true)}
        isAosrDisabled={false}
        onCreateAosr={() => setIsAosrCreating(true)}
        month={month}
        year={year}
      />
      {/* modals */}
      <SpecificationModal
        isOpen={isOpenJustification}
        onClose={() => setIsOpenJustification(false)}
        subMaterials={justificationData || []}
        name={ticketItem?.name}
        isLoading={isJustificationLoading}
      />
      {/* @ts-ignore */}
      <CreateRemarkModal
        /* TODO replace */
        isOpen={isOpenAddRemarkModal}
        setIsOpen={setIsOpenAddRemarkModal}
        workName={ticketItem?.name!}
        expenditureId={itemId}
        isGroup={isExpenditureGroup}
      />
      <RemarkModal
        /* TODO replace */
        isOpen={isOpenRemarkModal}
        setIsOpen={setIsOpenRemarkModal}
        workName={ticketItem?.name!}
        remarksCount={isShowOnlyAccepted ? ticketItem?.count_remarks_accepted! : ticketItem?.count_remarks_in_work!}
        expenditureId={itemId}
        isShowOnlyAccepted={isShowOnlyAccepted}
        isGroup={isExpenditureGroup}
      />
      <AcceptTicketModal
        isOpen={isAcceptingModal}
        onClose={closeAcceptModal}
        onApprove={acceptTicket}
        ticket={ticket}
        sectionId={sectionId}
        year={year}
        month={month}
      />
      <ActCreateModal
        isOpen={isAosrCreating}
        onClose={() => {
          setIsAosrCreating(false);
        }}
        expenditure={ticket}
        /* TODO */
        sectionId={sectionId}
        type={
          isExpenditureGroup ? AosrTypes.FOR_GROUP : isExpenditureInGroup ? AosrTypes.FOR_GROUP_EXP : AosrTypes.FOR_EXP
        }
        groupId={groupId}
      />
      <JournalReplacementsModal
        isOpen={isReplacementsModal}
        onClose={() => setIsReplacementsModal(false)}
        expenditure={ticket}
      />
      <JornalDoneModal
        isOpen={!!isTicketModal}
        data={ticket}
        onClose={() => setIsTicketModal(false)}
        stockId={stockId}
      />
      <AosrExistingModal
        canEdit={haveEditAosrPermission}
        canImportAosr={haveImportAosrPermission}
        isOpen={isAosrModal}
        onClose={() => setIsAosrModal(false)}
        ticket={ticket}
        sectionId={sectionId}
      />
      <AookExistingModal
        key={activeAook?.id}
        isOpen={!!activeAook}
        onClose={() => setActiveAook(null)}
        sectionId={sectionId}
        ticket={ticket}
        activeAook={activeAook!}
      />
    </TableReusableRow>
  );
};

export default JournalExecRow;
